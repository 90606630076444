
// import { Space, Image, Button, Dialog } from "antd-mobile";
import { Dialog } from "antd-mobile";
import { useState, useEffect } from "react";

// 定义 BeforeInstallPromptEvent 类型
interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;
    prompt(): Promise<void>;
}

function MyPwa() {
    const [visible, setVisible] = useState(false)
    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
    // 监听 beforeinstallprompt 事件
    const handleBeforeInstallPrompt = (e:Event) =>{
        console.log("e => ", e)
        const promptEvent = e as BeforeInstallPromptEvent;
        promptEvent.preventDefault()
        setDeferredPrompt(promptEvent)
    }
    useEffect(() => {
        // 检查是否在 PWA 环境中运行
        const isInstalledPWA = window.matchMedia('(display-mode: window-controls-overlay)').matches || 
                               window.matchMedia('(display-mode: standalone)').matches;
        setVisible(isInstalledPWA);
        console.log("isInstalledPWA =>",isInstalledPWA)

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
        console.log("Event listener added for beforeinstallprompt");

        return ()=>{
            console.log("Event listener removed for beforeinstallprompt 000");
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
            console.log("Event listener removed for beforeinstallprompt");
        }

    }, []); // 空依赖数组表示这个 effect 只在组件挂载时运行一次

   

    // 安装 pwa 
    const installPwa = () =>{
        if (deferredPrompt){
            deferredPrompt.prompt() // 触发安装提示
            deferredPrompt.userChoice.then((choiceResult) => {
                console.log("choiceResult.outcome ==>",choiceResult.outcome)
            })
            setVisible(true)
        }
    }

    return (
        // visible && deferredPrompt &&  (<>
        //     <Dialog 
        //         visible={!visible}
        //         content="Install the App"
        //         closeOnAction
        //         onAction={(action)=>{
        //             // action=> {key: 'cancel', text: '取消'}
        //             let key = action.key
        //             if (key === 'cancel'){
        //                 setVisible(true)
        //             }else{
        //                 // 安装 pwa
        //                 installPwa()
        //             }
        //         }}
        //         actions={[
        //             {
        //                 key: 'cancel',
        //                 text: '取消',
        //             },
        //             {
        //             key: 'confirm',
        //             text: '安装',
        //             },
        //         ]}
        //     />
        // </>)
        <>
            {!visible && deferredPrompt && (
                <Dialog 
                visible={!visible}
                content="Install the App"
                closeOnAction
                onAction={(action)=>{
                    // action=> {key: 'cancel', text: '取消'}
                    let key = action.key
                    if (key === 'cancel'){
                        setVisible(true)
                    }else{
                        // 安装 pwa
                        installPwa()
                    }
                }}
                actions={[
                    {
                        key: 'cancel',
                        text: '取消',
                    },
                    {
                    key: 'confirm',
                    text: '安装',
                    },
                ]}
                />
            )}
        </>
    );
  }
  
  export default MyPwa;